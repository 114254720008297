import React from 'react';
import Layout from '@containers/layout/layout.container';

const Content = () => {

    return (
        <Layout>
            <title>MongoDb</title>
            <section className="relative bg-background dark:bg-dark-charcoal p-4 overflow-hidden lg:pb-44 py-12 lg:pt-20 h-screen">
                <div className="container relative mx-auto z-20 pt-32 max-w-4xl ">
                    <h1 class="text-3xl font-bold mb-4">Connecting Stackbase.io to MongoDb</h1>
                    <div class="py-4 video-container">
                        <iframe src="https://www.youtube.com/embed/uQxTB66c2oY" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                    </div>
                    <a href="/" class="text-dark-grey hover:text-primary">&larr; Back</a>
                </div>
            </section>
        </Layout>
    );
};

export default Content;

